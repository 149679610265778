<template>
  <div class="homepage">
    <Tabs :mode="mode" :key="$route.fullPath"></Tabs>
  </div>
</template>

<script>
import Tabs from "@/components/tabs/Tabs.vue";

export default {
  name: "Livestream",
  components: {
    Tabs,
  },
  data() {
    return {
      mode: "light",
    };
  },
  computed: {
    settings() {
      return this.$store.state.Config.all;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    livestream() {
      return this.$store.getters["Livestreams/active"];
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
.homepage {
  padding-top: 0px;
  flex-grow: 1;
}
</style>
