<template>
  <iframe
    :src="isEvent ? `https://vimeo.com/${code}` : `https://player.vimeo.com/video/${code}`"
    id="vimeo-iframe"
    frameborder="0"
     width="640" height="360"
    allow="autoplay; fullscreen;"
    allowfullscreen
    data-vimeo-tracked="true"
    :class="{'full-height': !chatActive}"
  ></iframe>
</template>
<script>
export default {
  name: "Vimeo",
  props: ["code", "chatActive", "isEvent"],
};
</script>
