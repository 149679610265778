<template>
  <b-col cols="12" class="video-info">
    <h1 class="video-title text-color-title">{{ title }}</h1>
    <!-- TODO: create backend option to add start date to livestream -->
    <!-- <p class="video-date text-color-body">started streaming on 29 July 2021</p> -->
    <p class="video-description text-color-title" v-html="description"></p>
  </b-col>
</template>
<script>
export default {
  props: ["livestream"],
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    title() {
      if (this.livestream?.title) {
        return this.livestream?.title[this.locale];
      }
      return "";
    },
    description() {
      if (this.livestream?.description) {
        return this.livestream?.description[this.locale];
      }
      return "";
    },
  },
};
</script>
<style lang="scss">

.video-title {
  font-size: 20px;
}

.video-info {
  position: relative;
  padding-left: 15px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  // margin-top: 15px;
  max-width: 100%;
  color: white;
  // background-color: rgb(0 0 0 / 40%);
}
.video-date {
  max-width: 50%;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid grey;
}
@media only screen and (max-width: 999px) {
  .video-info {
    margin-top: 0px;
  }
}
</style>
